import React, { lazy } from "react";
import { Route } from "react-router-dom";


const CookiesAlert = lazy(() => import("../components/CookiesAlert"));

const Profile = lazy(() => import("../pages/Profile"));
const PrivacyTerms = lazy(() => import("../pages/PrivacyTerms"));
const MainEvaluations = lazy(() => import("../pages/MainEvaluations"));
const Evaluations = lazy(() => import("../pages/Evaluations"));
const FemininityForm = lazy(() => import("../pages/FemininityForm"));
const FilledFemininityForm = lazy(() =>
  import("../pages/FilledFemininityForm")
);
const MainFeminities = lazy(() => import("../pages/MainFemininities"));
const Form = lazy(() => import("../pages/Form"));
const Attendance = lazy(() => import("../pages/Attendance"));
const Scheduling = lazy(() => import("../pages/Scheduling"));
const SubjectsMain = lazy(() => import("../pages/SubjectsMain"));
const ChosenSubject = lazy(() => import("../pages/ChosenSubject"));
const AttendanceForm = lazy(() => import("../pages/AttendanceForm"));
const Courses = lazy(() => import("../pages/Courses"));

export default function Routes() {
  return (
    <>
      <CookiesAlert />
      <Route exact path="/termos" component={() => <PrivacyTerms />} />
      <Route exact path={["/perfil", "/"]} component={() => <Profile />} />
      <Route
        exact
        path="/avaliacao/principal"
        component={() => <MainEvaluations />}
      />
      <Route exact path="/avaliacao/tudo" component={() => <Evaluations />} />
      <Route
        exact
        path="/avaliacao/questionario"
        component={() => <FemininityForm />}
      />
      <Route
        exact
        path="/avaliacao_preenchida/:femininityId"
        component={() => <FilledFemininityForm />}
      />
      <Route
        exact
        path="/questionario/tudo"
        component={() => <MainFeminities />}
      />
      <Route exact path="/avaliacao/formulario" component={() => <Form />} />
      <Route exact path="/acompanhamento" component={() => <Attendance />} />
      <Route
        exact
        path="/agendamento/:scheduling_type?"
        component={() => <Scheduling />}
      />
      <Route exact path="/blog" component={() => <SubjectsMain />} />
      <Route exact path="/blog/:id" component={() => <ChosenSubject />} />
      <Route
        exact
        path="/avaliacao/:user_classification/:request_type"
        component={() => <AttendanceForm />}
      />
      <Route exact path="/cursos" component={() => <Courses />} />
    </>
  );
}
