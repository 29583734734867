import Cookies from "js-cookie";
import lottie from "lottie-web";
import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastNotifier } from "../helpers/ToastNotifier";
import api from "../services/api";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  let history = useHistory();

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStoragedData = async () => {
      const storagedUser = Cookies.get("authUser");
      const storagedToken = Cookies.get("authToken");

      await new Promise((resolve) => setTimeout(resolve, 800));

      if (storagedUser && storagedToken) {
        detectCookieChange(storagedUser, storagedToken);
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(JSON.parse(storagedUser));
      }

      setIsLoading(false);
    };

    loadStoragedData();
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector(".container"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/lotties/newborn.json"),
    });
  }, [isLoading]);

  const Login = async () => {
    try {
      const response = await api.post("/login", {
        email,
        password,
      });

      if (response.data.user.userType === 1) {
        setUser(response.data.user);

        api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

        Cookies.set("authUser", JSON.stringify(response.data.user));

        Cookies.set("authToken", response.data.token, {
          expires: 7,
        });

        ToastNotifier({
          toastMessage: "Usuário conectado com sucesso!",
          toastType: "success",
        });

        history.push("/perfil");
      } else throw new Error();
    } catch (e) {
      console.log(e);

      setIsLoading(false);
      history.push("/login");

      ToastNotifier({
        toastMessage: "Erro ao efetuar login na aplicação!",
        toastType: "error",
      });
    }
  };

  const detectCookieChange = async (user, token) => {
    try {
      const response = await api.get("users/token", {
        headers: { token },
      });

      const parsedUser = JSON.parse(user);

      if (
        response.data.user.userType !== parsedUser.userType ||
        response.data.user.id !== parsedUser.id
      ) {
        setUser(null);

        Cookies.remove("authToken");
        Cookies.remove("authUser");

        if (Cookies.get("acceptCookies")) Cookies.remove("acceptCookies");

        if (+user.userType !== 1) {
          history.push("/login");

          ToastNotifier({
            toastMessage: "Usuário desconectado por ações suspeitas!",
            toastType: "warn",
          });
        }
      }
    } catch (e) {
      setUser(null);
      console.log(e);
    }
  };

  const Logout = () => {
    setUser(null);

    Cookies.remove("authToken");
    Cookies.remove("authUser");

    if (Cookies.get("acceptCookies")) Cookies.remove("acceptCookies");

    window.location.href = "https://www.aurorafemtech.com.br/";
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          className="container"
          style={{
            width: "300px",
            height: "300px",
          }}
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        Login,
        Logout,
        setEmail,
        setPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
