import React, { Suspense } from "react";
import Routes from "./routes";
import { Switch } from "react-router-dom";
import Notifier from "./components/ToastNotifier";
import { AuthProvider } from "./contexts/auth";
import { Box, LinearProgress } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <>
      <AuthProvider>
        <Switch>
          <Suspense
            fallback={
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            }
          >
            <Routes />
          </Suspense>
        </Switch>
        <Notifier />
      </AuthProvider>
    </>
  );
}
