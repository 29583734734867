import React from "react";
import CookiesAlert from "../components/CookiesAlert";
import { useAuth } from "../hooks/useAuth";
import OtherRoutes from "./OtherRoutes";
import SignRoutes from "./SignRoutes";


export default function Routes() {
  const { signed } = useAuth();

  return signed ? (
    <>
      <OtherRoutes />
      <CookiesAlert />
    </>
  ) : (
    <SignRoutes />
  );
}
