import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import Login from "../pages/Login";
const PrivacyTerms = lazy(() => import("../pages/PrivacyTerms"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const SignUp = lazy(() => import("../pages/SignUp"));

export default function SignRoutes() {
  return (
    <>
      <Route path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login" component={Login} />
      <Route exact path="/termos" component={() => <PrivacyTerms />} />
      <Route exact path="/forgot" component={() => <ForgotPassword />} />
      <Route exact path="/signup" component={() => <SignUp />} />
    </>
  );
}
