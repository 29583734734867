import React, { useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Alert, Box, Button, Collapse } from "@material-ui/core";

export default function CookiesAlert() {
  let history = useHistory();

  const [isOpenAlert, setIsOpenAlert] = useState(true);

  const acceptCookies = () => {
    Cookies.set("acceptCookies", true);
  };

  const hasCookiesConsent = Boolean(Cookies.get("acceptCookies"));

  return (
    <>
      {hasCookiesConsent ? (
        false
      ) : (
        <Box
          position="fixed"
          bottom={10}
          zIndex={999}
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Collapse in={isOpenAlert}>
            <Alert
              variant="filled"
              severity="info"
              sx={{
                "& .MuiAlert-icon": {
                  height: "min-content",
                  alignSelf: "center",
                },
              }}
              action={
                <div style={{ alignSelf: "center", display: "flex" }}>
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px" }}
                    onClick={() => history.push("/termos")}
                    color="inherit"
                    size="small"
                  >
                    Ler termos
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsOpenAlert(false);
                      acceptCookies();
                    }}
                    color="inherit"
                    size="small"
                  >
                    Aceitar
                  </Button>
                </div>
              }
            >
              Este site utiliza cookies para melhorar sua experiência. Ao
              navegar no sistema, você concorda em utilizá-los.
            </Alert>
          </Collapse>
        </Box>
      )}
    </>
  );
}
