import { useState } from "react";
import { GoMail } from "react-icons/go/index";
import { BiLock } from "react-icons/bi/index";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai/index";
import { FcCheckmark } from "react-icons/fc/index";
import { MdClose } from "react-icons/md/index";
import { validateEmail } from "../../utils/validator";
import line from "../../assets/login-line.png";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "@material-ui/core";
import {
  MainContainer,
  FormContainer,
  WelcomeContainer,
  MainTitle,
  SpanMessage,
  EmailContainer,
  DivEmailLabel,
  Label,
  DivEmailInput,
  Input,
  PasswordContainer,
  Icon,
  RememberContainer,
  ForgotPassword,
  SignUpContainer,
  LabelAccount,
  SignUpLink,
  ButtonContainer,
  LoginButton,
  DivPasswordLabel,
  DivPasswordInput,
} from "./styles";

export default function Login() {
  document.title = "Aurora Femtech - Login";

  const { Login, setEmail, setPassword } = useAuth();

  const [hideShowPassword, setHideShowPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    await Login();
  };

  const handleEmailValidation = (value) => {
    setIsValidEmail(validateEmail(value));
  };

  const handleTogglePasswordVisibility = () => {
    setHideShowPassword(hideShowPassword ? false : true);
  };

  return (
    <>
      <MainContainer>
        <FormContainer onSubmit={handleLogin}>
          <WelcomeContainer>
            <MainTitle>Olá!</MainTitle>
            <SpanMessage>Faça login para ter acesso ao sistema</SpanMessage>
          </WelcomeContainer>
          <EmailContainer>
            <DivEmailLabel>
              <Label>E-mail</Label>
            </DivEmailLabel>
            <DivEmailInput>
              <GoMail color="#626679" size={32} />
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleEmailValidation(e.target.value);
                }}
                name="email"
                type="email"
                placeholder="Digite seu e-mail"
                required
              />
              <Icon isNotPointer>
                {isValidEmail ? (
                  <FcCheckmark color="#3f4254" size={28} />
                ) : (
                  <MdClose color="red" size={28} />
                )}
              </Icon>
            </DivEmailInput>
          </EmailContainer>
          <PasswordContainer>
            <DivPasswordLabel>
              <Label>Senha</Label>
            </DivPasswordLabel>
            <DivPasswordInput>
              <BiLock color="#626679" size={32} />
              <Input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type={hideShowPassword ? "text" : "password"}
                placeholder="Digite sua senha"
                required
              />
              <Icon onClick={handleTogglePasswordVisibility}>
                {hideShowPassword ? (
                  <AiOutlineEye color="#3f4254" size={28} />
                ) : (
                  <AiOutlineEyeInvisible color="#3f4254" size={28} />
                )}
              </Icon>
            </DivPasswordInput>
          </PasswordContainer>
          <RememberContainer>
            <ForgotPassword to="/forgot">Esqueceu a senha?</ForgotPassword>
          </RememberContainer>
          <img src={line} />
          <SignUpContainer>
            <LabelAccount>Não tem conta?</LabelAccount>
            <SignUpLink to="/signup">Cadastre-se</SignUpLink>
          </SignUpContainer>
          <ButtonContainer>
            <Button
              type="submit"
              variant="container"
              sx={{
                backgroundColor: "#ff7346",
                color: "#fff",
                fontFamily: "QuicksandRegular",
                fontSize: "14px",
                textTransform: "capitalize",
                border: "none",
                outline: "none",
                cursor: "pointer",
                textDecoration: "none",
                height: "40px",
                width: "110px",
                "&:hover": {
                  opacity: "0.9",
                  backgroundColor: "#ff7346",
                },
              }}
            >
              Login
            </Button>
          </ButtonContainer>
        </FormContainer>
      </MainContainer>
    </>
  );
}
