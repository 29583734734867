import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #e5e5e5;
`;

export const FormContainer = styled.form`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  background-color: #fff;
  min-width: 450px;

  @media(max-width: 768px) {
    /* flex: 1; */
    width: 100%;
    min-width: 0px;
    height: 100%;
  }
`;

export const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const MainTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3f4254;
  text-align: center;
  font-family: QuicksandSemiBold;
  margin-top: 52px;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 28px;
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25),
    0px 0px 40px rgba(0, 0, 0, 0.25);
`;

export const SpanMessage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626679;
  font-family: QuicksandRegular;
  font-size: 14px;
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  height: 100px;
  margin: 20px 0px;
  box-shadow: 0px 5px 6px rgba(239, 241, 249, 0.8);
  border-radius: 8px;

  @media(max-width: 768px) {
    min-width: 0px;
    width: 80%;
  }
`;

export const DivEmailLabel = styled.div`
  padding: 8px 20px;
`;

export const DivEmailInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  @media(max-width: 768px) {
    margin: 0;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: MontserratRegular;
  font-size: 14px;
  text-align: center;
  color: #3f4254;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 6px 18px;
  width: 200px;
  font-family: QuicksandRegular;
  font-size: 14px;
  padding-right: 36px;


  @media(max-width: 768px) {
    flex: 1;
    width: auto;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  height: 100px;
  box-shadow: 0px 5px 6px rgba(239, 241, 249, 0.8);
  border-radius: 8px;

  @media(max-width: 768px) {
    min-width: 0px;
    width: 80%;
  }
`;

export const DivPasswordLabel = styled.div`
  padding: 8px 20px;
`;

export const DivPasswordInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;


  @media(max-width: 768px) {
    margin: 0px;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => props.isNotPointer ?? "pointer"};
`;

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 30px;
  white-space: nowrap;
`;

export const Checkbox = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.2);
  font-size: 16px;
  font-weight: 400;
  color: #3f4254;
  margin-right: 8px;
  filter: hue-rotate(150deg);
`;

export const Text = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin-right: 50px;
  color: #626679;
  font-family: QuicksandRegular;
  font-size: 14px;
`;

export const ForgotPassword = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  width: 100%;
  height: 30px;
  color: #626679;
  font-family: QuicksandRegular;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 50px;
`;

export const LabelAccount = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #626679;
  font-family: QuicksandRegular;
  font-size: 14px;
`;

export const SignUpLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  outline: none;
  text-decoration: none;
  height: 30px;
  color: #ff7346;
  font-family: QuicksandRegular;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
`;
